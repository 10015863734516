import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        token: '',
        project: null,
        customer: ''
    },
    getters: {
        getToken: state => state.token,
        getProject: state => state.project,
        isAuthenticated: state => state.token !== '',
        getCustomerName: state => state.customer
    },
    mutations: {
        saveToken(state, token) {
            state.token = token;
            localStorage.setItem('token', JSON.stringify(token))
        },
        removeToken() {
            localStorage.removeItem('token')
        },
        saveProject(state, project) {
            state.project = project;
        },
        saveCustomerName(state, data) {
            state.customer = data
        }
    }
})

export default store
