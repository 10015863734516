import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import api from "@/api";

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  const authenticated = store.getters.isAuthenticated;
  if (!authenticated) {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      store.commit("saveToken", token);
    }
  }
  api.validateToken().then(
    () => {
      next()
    },
    () => {
      router.push({
        name: 'Login',
        params: {
          returnTo: to.path,
          query: to.query,
        },
      });
    }
  )
};

const routes = [
  {
    path: '/',
    name: 'Root',
    component: () => import(/* webpackChunkName: "about" */ '../views/Homepage.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/connectors/:id',
    name: 'Connectors',
    component: () => import(/* webpackChunkName: "connectors" */ '../views/Connectors.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/homepage',
    name: 'Homepage',
    component: () => import(/* webpackChunkName: "homepage" */ '../views/Homepage.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/plannerlist/:id',
    name: 'PlannerList',
    component: () => import('../views/PlannerList.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/plannerBackup/:id',
    name: 'PlannerBackup',
    component: () => import('../views/PlannerBackup.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/grouplist/:id',
    name: 'GroupList',
    component: () => import('../views/GroupList.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/usersGroupList/:id',
    name: 'UserGroupList',
    component: () => import('../views/UserGroupList.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/mapping/:id',
    name: 'UserMapping',
    component: () => import(/* webpackChunkName: "mapping" */ '../views/UserMapping.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/license',
    name: 'License',
    component: () => import('../views/License.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/resetpassword/:email/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/confirm/:id',
    name: 'confirm',
    component: () => import('../views/ConfirmAccount.vue'),
  },
  {
    path: '/AzureAdAppConsent',
    name: 'ConsentAzureAdApp',
    component: () => import('../views/ConsentAzureAdApp.vue'),
  },
  {
    path: '/TrelloAppConsent',
    name: 'ConsentTrelloApp',
    component: () => import('../views/ConsentTrelloApp.vue'),
  },
  {
    path: '/MondayAppConsent',
    name: 'ConsentMondayApp',
    component: () => import('../views/ConsentMondayApp.vue'),
  },
  {
    path: '/ClickUpAppConsent',
    name: 'ConsentClickUpApp',
    component: () => import('../views/ConsentClickUpApp.vue'),
  },
  {
    path: '/Buy',
    name: 'Buy',
    component: () => import('../views/Buy.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/PaymentSuccess',
    name: 'PaymentSuccess',
    component: () => import('../views/PaymentSuccess.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/generateToken',
    name: 'ConesentSlackApp',
    component: () => import('../views/ConsentSlackApp.vue'),
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
